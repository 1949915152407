
















































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import API, { Types } from '@/modules/API';
import ExportData from '@/components/ExportData.vue';
import VueApexCharts from 'vue-apexcharts';
import chartConfig from '../../devices/chart.config';
import Utils from '@/modules/Utils';
import moment from 'moment';
import momentTZ from 'moment';
import DatePicker from '@/pages/reports/components/DatePicker.vue';

@Component({
    components: {
        DatePicker,
        ExportData,
        VueApexCharts
    }
})

export default class BillingTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    // @ts-ignore
    @Prop() device;

    tousValues = [];
    loading = false;
    periodSelected = '1weeks';
    noData = false;

    series = [{
        name: 'Billing',
        data: []
    }];

    start_date = null;
    end_date = null;
    start_time = {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    };

    end_time = {
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59
    };
    chartOptions = chartConfig.chartOptionsBillingTab;

    async mounted() {
        await this.loadData(null);
    }

    get headers() {
        return [
            {
                text: this.$t('Date'),
                value: 'date',
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            },
            {
                text: this.$t('Tariff 1 (Peak)') + ' Wh',
                value: 'tarif1',
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            },
            {
                text: this.$t('Tariff 2 (High)') + ' Wh',
                value: 'tarif2',
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            },
            {
                text: this.$t('Tariff 3 (Low)') + ' Wh',
                value: 'tarif3',
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            },
            {
                text: this.$t('Total Energy') + ' Wh',
                value: 'total',
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            }
        ];
    }

    get periodsList() {
        return [
            { text: this.$t('Last 24 Hours'), value: '1days' },
            { text: this.$t('Last 48 Hours'), value: '2days' },
            { text: this.$t('Last 7 Days'), value: '1weeks' },
            { text: this.$t('Last 30 Days'), value: '1months' },
            { text: this.$t('Custom Range'), value: 'custom' }
        ];
    }

    @Watch('periodSelected')
    async loadData(period) {
        this.noData = false;
        this.tousValues = [];

        if (this.periodSelected === 'custom' && typeof period !== 'object') {
            this.setDefaultDates();
            return;
        }
        this.loading = true;
        let touStreamHistory;
        try{
            touStreamHistory = await API.get(
                Types.DEVICES, `${this.device.id}/streams/${this.device.id}.tou/history/`, typeof period === 'object' && period || { since: this.periodSelected }, {}
            );
        }catch (e){}

        if (!touStreamHistory || !touStreamHistory.length) {
            this.loading = false;
            this.noData = true;
            return;
        }
        let sumTariff1 = 0;
        let sumTariff2 = 0;
        let sumTariff3 = 0;
        // let sumTotal = 0;

        touStreamHistory.reverse().forEach((el) => {
            try {
                const parsedElement = JSON.parse(el[1].replaceAll('\'', '\"'));
                if (parsedElement['Summary total energy'] === undefined || 
                    parsedElement['Tariff #1'] === undefined || 
                    parsedElement['Tariff #2'] === undefined ||
                    parsedElement['Tariff #3'] === undefined
                ){
                    return;
                }

                const formattedDate = Utils.convertTimestamp(el[0], 'DD.MM.YYYY', this.projectTimezone);
                const info =  {
                    date: formattedDate,
                    tarif1: Utils.numberWithCommas(+parsedElement['Tariff #1']),
                    tarif2: Utils.numberWithCommas(+parsedElement['Tariff #2']),
                    tarif3: Utils.numberWithCommas(+parsedElement['Tariff #3']),
                    total: Utils.numberWithCommas(+parsedElement['Summary total energy'])
                };
                this.tousValues.push(info);

                sumTariff1 += parseFloat(parsedElement['Tariff #1']);
                sumTariff2 += parseFloat(parsedElement['Tariff #2']);
                sumTariff3 += parseFloat(parsedElement['Tariff #3']);
                // sumTotal += parseFloat(parsedElement['Summary total energy']);
            }
            catch (error) {
                console.log('%c' + 'error:' + error, 'color: red');
            }
        });
        sumTariff1 = +sumTariff1.toFixed(2);
        sumTariff2 = +sumTariff2.toFixed(2);
        sumTariff3 = +sumTariff3.toFixed(2);

        this.series = [{
            name: 'Billing',
            data: [sumTariff1, sumTariff2, sumTariff3]
        }];
        this.loading = false;
    }

    setDefaultDates() {
        this.setStartDate(momentTZ().tz(this.projectTimezone).subtract(1, 'days').format('YYYY-MM-DD'));
        this.setEndDate(momentTZ().tz(this.projectTimezone).format('YYYY-MM-DD'));
    }

    setStartDate(start) {
        this.start_date = start;
    }

    setEndDate(end) {
        this.end_date = end;
    }

    async applyCustomRange(){
        this.loadData(this.getCustomPeriod());
    }

    getCustomPeriod() {
        if (this.start_date > this.end_date) {
            const temp = this.start_date;
            this.start_date = this.end_date;
            this.end_date = temp;
        }
        return {
            from: moment(this.start_date).set(this.start_time).valueOf(),
            to: moment(this.end_date).set(this.end_time).valueOf()
        };
    }

}
